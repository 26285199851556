import React, {Component} from 'react';

import Loader from './loader'
import Axios from 'axios';
import { BASE, SIGNUP_STATUS, SIGNUP, SIGNUP_PROGRESS } from '../api/client';


const serializeForm = (form) => {
    var vals = {}
    for (var i = 0; i < form.elements.length; i++) {
        vals[form.elements[i].name] = form.elements[i].value
    }
    return vals
}

const sleep = m => new Promise(r => setTimeout(r, m))

class SignupAvailable extends Component {
    debug = false
    state = {loading: false, success: false}
    handleSubmit = async () => {
        console.log("submit called")
        var serializedForm = serializeForm(document.getElementById("signup-form"))        
        this.setState({loading: true, success: false})
        try {
            let resp = await Axios.post(BASE + SIGNUP, serializedForm)
            let taskId = resp.data.id
            while (true) {
                await sleep(2000)
                let statusData = await this.pollForStatus(taskId)
                if (statusData.ok) {
                    if (statusData.status === "complete") {
                        this.setState({loading:false, success:true})
                        break
                    }
                } else {
                    this.setState({loading:false, success:false})
                    document.getElementById("signup-feedback").innerText = statusData.error
                    break
                }
            }
        } catch(e) {
            this.setState({loading:false, success:false})
            document.getElementById("signup-feedback").innerText = e
        }
    }

    pollForStatus = async (id) => {
        console.log("Polling for signup status")
        try {
            let resp = await Axios.post(BASE + SIGNUP_PROGRESS, {"id": id})
            return resp.data
        } catch(e) {
            return {ok: false, error: e}
        }
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src="https://checkout.stripe.com/checkout.js" ;
        script.className="stripe-button";
        script.dataset.key=this.debug ? "pk_test_sxsSw6ZFrbmLTKho0A4CRd0s": "pk_live_01AhXPq4l2aCpKR986P71uGl";
        script.dataset.amount="4000";
        script.dataset.name="DISTORTION";
        script.dataset.description="One month DISTORTION access (billed monthly)";
        script.dataset.image="https://stripe.com/img/documentation/checkout/marketplace.png";
        script.dataset.locale="auto";
        script.dataset.zipCode="true";
        let form = document.getElementById('signup-form');
        form.appendChild(script);

        document.getElementById("signup-form").submit = this.handleSubmit
    }
    
    goToAccount = () => {
        document.location = "/account"
    }
    render() {
        console.log(this.state)
        return(
            this.state.loading ? <Loader/> : this.state.success ? (<div className="information"> 
                thank you for signing up!<br/> please access your account here to request a slack invitation<br/>
                <br/><button onClick={this.goToAccount} style={{"fontFamily":"bb" ,"fontSize":"1em", "fontColor": "white", "border": "none", "height": "75px", "width":"150px"}}>go to account</button>
            </div>): (<div className="information">
                signups are <br/><br/>OPEN<br/><br/>
                $40 a month. slack access. billed monthly. cancel any time<br/><br/>
                by signing up you accept our <a href="/static/distortionTOS.pdf" target="_blank" style={{color:"red"}}>terms of service</a>
                    <form id="signup-form">
                        <label htmlFor="signup-username">email</label><br/>
                        <input type="text" name="username" id="signup-username"/><br/><br/>
                        <label htmlFor="signup-password">password</label><br/>
                        <input type="password" name="password" id="signup-password"/><br/>
                        <br/>
                        <p id="signup-feedback"></p>
                    </form>
            </div>)
        )
    }
}

const SignupUnavailable = () => {
    return(
        <div className="information">
            signups are <br/><br/>CLOSED
        </div>
    )
}

const ErrorEncountered = () => {
    return(
        <div className="verticalmid information">
            an error was encountered trying to receive signup availability. please refresh the page.
        </div>
    )
}

class JoinForm extends Component {
    state = {loading: true, available: false, error: false}
    getSignupStatus = () => {
        Axios.get(BASE + SIGNUP_STATUS).then(
            response => {
                // console.log(response)
                if (response.data.ok) {
                    this.setState({loading: false, available: response.data.available, error: false})
                } else {
                    this.setState({loading: false, available: false, error: true})
                }
            }
        )
    }
    componentDidMount() {
        // this.stub()
        this.getSignupStatus()
    }

    render() {
        return(<div className="joinForm">
                 {this.state.loading ? <Loader/> : this.state.error ? <ErrorEncountered/> : this.state.available ? <SignupAvailable/> : <SignupUnavailable/>}
            </div>)
    }
}

export default JoinForm