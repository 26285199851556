const BASE = "https://distort.sh"
// const BASE = "http://127.0.0.1:8000"
// const BASE = "https://dev.crep.supply"

const LOGIN = "/api/login"
const SIGNUP = "/api/signup"
const SIGNUP_PROGRESS = "/api/signup/progress"
const SIGNUP_STATUS = "/api/signup_status"
const FORGOT_PASSWORD = "/api/forgot_password"

export {BASE, LOGIN, SIGNUP_STATUS, SIGNUP, SIGNUP_PROGRESS, FORGOT_PASSWORD}