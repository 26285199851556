import React from 'react'

const sitelist = [
    "rsvpgallery.com",
"rise45.com",
"shop.extrabutterny.com",
"shop-usa.palaceskateboards.com",
"noirfonce.eu",
"packershoes.com",
"beatniconline.com",
"cncpts.com",
"shop.exclucitylife.com",
"shopnicekicks.com",
"offthehook.ca",
"properlbc.com",
"us.octobersveryown.com",
"shop.renarts.com",
"rockcitykicks.com",
"sneakerpolitics.com",
"nomadshop.net",
"brooklynwaynyc.com",
"us.bape.com",
"burnrubbersneakers.com",
"wishatl.com",
"shop.bbcicecream.com",
"suede-store.com",
"shop.reigningchamp.com",
"commonwealth-ftgg.com",
"par5-milano-yeezy.com",
"sneakerworldshop.com",
"Sole88.com",
"solestop.com",
"www.trophyroomstore.com",
"www.xhibition.co",
"www.oipolloi.com",
"www.deadstock.ca",
"www.bowsandarrowsberkeley.com",
"www.saintalfred.com",
"www.minishopmadrid.com",
"www.blendsus.com",
"www.solefly.com",
"shop.bdgastore.com",
"www.manorphx.com",
"www.socialstatuspgh.com",
"www.unknwn.com",
"www.highsandlows.net.au",
"www.featuresneakerboutique.com",
"www.rimenyc.com",
"www.capsuletoronto.com",
"kith.com",
"www.blkmkt.us",
"www.notre-shop.com",
"www.12amrun.com",
"www.courtsidesneakers.com",
"apbstore.com",
"centre214.com",
"www.a-ma-maniere.com",
"www.bbbranded.com",
"nrml.ca",
"www.addictmiami.com",
"atmosny.com",
"cityblueshop.com",
"lapstoneandhammer.com",
"shoegallerymiami.com",
"thesportsedit.com",
"uptownmia.com",
"vanrunco.com",
"westnyc.com",
"shop.fourtwofouronfairfax.com",
"callme917.com",
"www.theclosetinc.com",
"www.cntrbndshop.com",
"www.philipbrownemenswear.co.uk",
"soleclassics.com",
"concrete.nl",
"justdon.com",
"uk.octobersveryown.com",
"eflash-us.doverstreetmarket.com",
"eflash.doverstreetmarket.com",
"www.rooneyshop.com",
"www.kongonline.co.uk",
"www.pampamlondon.com",
"thedarksideinitiative.com",
"www.thedarksideinitiative.com",
"www.oneness287.com",
"www.alumniofny.com",
"www.stoneisland.co.uk",
"clot.com",
"www.amongstfew.com",
"mrcnoir.com",
"bbcicecream.eu",
"www.dope-factory.com",
"fearofgod.com",
"www.machusonline.com",
"www.abovethecloudsstore.com",
"shop.travisscott.com",
"www.leaders1354.com",
"creme321.com",
"eflash-sg.doverstreetmarket.com",
"www.stampd.com",
"shop.justintimberlake.com",
"sesinko.com",
"lambcrafted.com",
"lessoneseven.com",
"undefeated.com",
"www.thegoodlifespace.com",
"www.ficegallery.com",
"eflash-jp.doverstreetmarket.com",
"www.incu.com",
"alifenewyork.com",
"www.worldofhombre.com",
"www.funko-shop.com",
"store.highsnobiety.com",
"stashedsf.com",
"www.c2h4losangeles.com",
"usgstore.com.au",
"store.travisscott.com",
"kicks-hawaii.myshopify.com",
"cruvoir.com",
"likelihood.us",
"www.hotrodla.com",
"todayclothing.com",
"www.bbcicecream.eu",
"twdsupplydrop.com",
"www.johnelliott.co",
"launch.toytokyo.com",
"152store.com",
"lacedlifestyle.com",
"lustmexico.com",
"thepremierstore.com",
"www.urbanindustry.co.uk",
"bimtoy.com",
"store.unionlosangeles.com/",
"nohble.com",
"shop.palaceskateboards.com",
"johngeigerco.com",
"oqium.com",
"hypefest.com",
"area51store.co.nz",
"www.1032space.com",
"www.thesurestore.com",
"launches.hanon-shop.com",
"epitomeatl.com",
"shop.finalmouse.com",
"reign.co",
"over9000.com",
"diamondsupplyco.com",
"wearebraindead.com",
"www.hanon-shop.com",
"unionjordan.com",
"us.cdgcdgcdg.com",
"releases.kith.com",
"canteen.theberrics.com",
"welcomeleeds.com",
"flatspot.com",
"focuspocus.co.uk",
"upsskateshop.com.au",
"www.alyxstudio.com",
"510skateboarding.com",
"sweetstuffinside.com",
"shop.atlasskateboarding.com",
"shop.ftcsf.com",
"shop.welcomeskateboards.com",
"www.bigairshop.com",
"amigoskateshop.com",
"shop.havenshop.com",
"+",
"+",
"plus over a hundred more!"
]

const anims = [
    "slideInUp", "slideInDown", "slideInLeft", "slideInRight", "lightSpeedIn"
]


const Sites = () => {
    return <div className="information">
{sitelist.map((site) => <div className={"animated " + anims[Math.floor(Math.random()*anims.length)] + " once"}>{site}</div>)}
    </div>
}

export default Sites