import React, { Component } from 'react';
import {Link} from 'react-router-dom';
// import logo from './logo.svg';
import './menu.css';

class Menu extends Component {
  menuOptions = [
    {
        title: "join!",
        url: "/join"
    },
    {
        title: "sites?  ",
        url: "/sites"
    },
    {
        title: "login!",
        url: "/login"
    },
    {
        title: "features?",
        url: "/features"
    },
    {
        title: "about!",
        url: "/about"
    },
    {
        title: "terms?",
        url: "/static/distortionTOS.pdf",
        openExternal: true
    },
    {
        title: "consulting!",
        url: "/consulting"
    }
  ]

  render() {
    return (
      <div className="menu">
       {
        this.menuOptions.map( 
            (option) => 
            !option.openExternal 
              ?
            <div className="menuitem animated fadeIn once" key={option.title}><Link to={option.url}>{option.title}</Link></div>
              :
            <div className="menuitem animated fadeIn once" key={option.title}><a href={option.url}>{option.title}</a></div>  
        )   
       }
      </div>
    )
  }
}

export default Menu;