import React from 'react';

const Consulting = () => <div className="information animated fadeIn once">
    <div className="">
        we also provide professional services for the following industries<br/>
        # high performance web scraping<br/>
        # data collection<br/>
        # retail process automation<br/>
        # reverse engineering<br/>

        <br/>
        for information please email
        <div style={{fontSize: "1.5em"}}><br/>admin@distort.sh<br/></div>
     </div>
</div>
export default Consulting