import React, { Component } from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import './App.css';
import Menu from './components/menu'
import LoginForm from './components/login'
import Features from './components/features'
import About from './components/about'
import JoinForm from './components/join'
import Mosaic from './components/mosaic'
import Sites from './components/sites'
import ForgotPass from './components/forgotpass'
import Consulting from './components/consulting'
import {StripeProvider} from 'react-stripe-elements'

class App extends Component {
  debug = false
  render() {
    return (
      <StripeProvider apiKey={this.debug ? "pk_test_sxsSw6ZFrbmLTKho0A4CRd0s" : "pk_live_01AhXPq4l2aCpKR986P71uGl"}>
      <BrowserRouter>
        <div className="main">
          <header className="header  animated fadeIn once">
            <a href="/"><video alt="big ass distortion logo" playsInline muted autoPlay loop><source src="assets/logo.mp4" type="video/mp4"/></video></a>
          </header>
          <div className="content animated fadeIn once" style={{"textAlign":"center"}}>
            {/* <div className="contentblock"> */}
            <Route exact path="/" component={Mosaic}/>
            <Route path="/login" component={LoginForm}/>
            <Route path="/join" component={JoinForm}/>
            <Route path="/sites" component={Sites}/>
            <Route path="/features" component={Features}/>
            <Route path="/about" component={About}/>
            <Route path="/consulting" component={Consulting}/>
            <Route path="/forgotpass" component={ForgotPass}/>
          </div>
          <Menu/>
        </div>
      </BrowserRouter>
      </StripeProvider>

    );
  }
}

// class TestComponent extends Component {
//   render() {
//     return           <div className="content-img animated fadeIn once"><img src="assets/features_t2.png" alt="features available" style={{"maxHeight": "100%", "maxWidth":"100%", "verticalAlign": "middle"}}></img></div>

//   }
// }

export default App;
