import React from 'react'

const Loader = () => {
    return(
    <div id="animation-container">
        <div id="topbar"></div>
        <div id="distortion">DISTORTION</div>
        <div id="botbar"></div>
    </div>
    )
}

export default Loader