import React from 'react';

const About = () => <div className="information animated fadeIn once">
    <div className="">
        have you ever wondered how the same people are always able to purchase the latest releases<br/><br/>
        have you ever wondered how they knew exactly when and where those sneakers you wanted dropped<br/><br/>
        with distortion, every member has access to those advantages. <br/><br/>
        we provide custom product and website monitoring service that gives you the chance to buy all limited sneakers and streetwear.<br/>
        everything is custom built from the ground up for the fastest performance.<br/>
        <br/>
        with over 200 websites monitored, you will never miss another release.<br/>
        trust us, just like our members have since 2016.<br/><br/>

        for enquiries and issues, email us<br/>
        <div style={{fontSize: "1.5em"}}><br/>admin@distort.sh<br/></div>
     </div>
</div>
export default About