import React, {Component} from 'react';
import {Link} from "react-router-dom"
import "../App.css"
import 'axios'

import Axios from 'axios';
import {BASE, LOGIN} from '../api/client'

class LoginForm extends Component {
    loginHandler = (e) => {
        e.preventDefault()
        Axios.post(BASE + LOGIN, {
            email: document.getElementsByName("username")[0].value,
            password: document.getElementsByName("password")[0].value
        }).then(
            response => {
                console.log(response);
                if (!response.data.success) {
                    document.getElementById('login-feedback').innerText = response.data.error;
                } else {
                    document.location = response.data.redirect
                }
            }
        )
    }

    render() {
        return(
        <div className="verticalmid information">
            <form id="login-form" className="animated fadeIn once" method="POST" action="/">
                <label htmlFor="login-username">username</label><br/>
                <input type="text" name="username" id="login-username"/><br/><br/>
                <label htmlFor="login-password">password</label><br/>
                <input type="password" name="password" id="login-password"/><br/>
                <br/>
                {/* <button style={{"fontFamily":"bb" ,"fontSize":"2em", "fontColor": "white", "border": "solid", "height": "50px", "width":"50px", backgroundColor: "white"}} onClick={this.loginHandler}>go</button> */}
                <button className="stripe-button-el" style={{"height": "50px", "width":"50px", "fontSize": "2em !important", fontFamily: "bb"}} onClick={this.loginHandler}>go</button>
                <p id="login-feedback"></p>
                <br/>
                <Link to="forgotpass">forgot password</Link>
            </form>
        </div>)
    }
}

export default LoginForm