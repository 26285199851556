import React, {Component} from 'react';
import "../App.css"
import 'axios'

import Axios from 'axios';
import {BASE, FORGOT_PASSWORD} from '../api/client'

class ForgotPass extends Component {
    resetHandler = (e) => {
        e.preventDefault()
        var button = document.getElementsByClassName("stripe-button-el")[0]
        button.disabled = true
        button.innerText = "loading.."
        Axios.post(BASE + FORGOT_PASSWORD, {
            username: document.getElementsByName("username")[0].value,
        }).then(
            response => {
                var target = document.getElementById('login-feedback')                
                if (!response.data.ok) {
                    target.innerText = response.data.error
                    target.className = "error"
                    // document.getElementById('login-feedback').innerText = response.data.error;
                } else {
                    target.innerText = response.data.message
                    target.className = "success"
                }
                button.innerText = "reset"
                button.removeAttribute("disabled")
            }
        )
    }

    render() {
        return(
        <div className="verticalmid information">
            <form id="login-form" className="animated fadeIn once" method="POST" action="/">
                <label htmlFor="login-username">username</label><br/>
                <input type="text" name="username" id="login-username"/><br/><br/>
                <button className="stripe-button-el" style={{"height": "50px", "width":"150px", "fontSize": "2em !important", fontFamily: "bb"}} onClick={this.resetHandler}>reset</button>
                <br/>
                <p id="login-feedback"></p>
            </form>
        </div>)
    }
}

export default ForgotPass